import stylesConfig from 'theme/config';

const styles = {
  '.MuiSvgIcon-root': {
    fill: stylesConfig.colors.secondary,
  },
  helperText: {
    margin: 0,
  },
};

export default styles;
