import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Dashboard from 'pages/dashboards/admin/AdminDashboard';
import LogoutPage from 'pages/authentication/logout-page/LogoutPage';
import NoRouteFound from 'pages/errors/LoggedIn/404';

const AdminUserRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/logout" component={LogoutPage} />
      <Route path="*" component={NoRouteFound} />
    </Switch>
  );
};

AdminUserRoutes.propTypes = {};
export default AdminUserRoutes;
