import stylesConfig from 'theme/config';

const chartProps = {
  colors: [stylesConfig.colors.success, stylesConfig.colors.primary],
  stroke: {
    show: true,
  },
  fill: {
    opacity: 0.0,
  },
  zoom: {
    enabled: false,
  },
  toolbar: {
    show: true,
  },
  plotOptions: {
    radar: {
      polygons: {
        strokeColors: stylesConfig.colors.darkGrey,
        strokeWidth: 0.2,
      },
    },
  },
  label: {
    color: stylesConfig.colors.secondary,
  },
};

export default chartProps;
