import stylesConfig from 'theme/config';

const styles = {
  headingBox: {
    height: '80px',
    background: 'crimson',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: stylesConfig.spacing.small,
  },
  linkGroupTitle: {
    marginBottom: 0,
    color: 'white',
    font: 'normal normal bold 22px/22px Montserrat',
    padding: '15px',
    textAlign: 'center',
  },
};

export default styles;
