import React, { useContext } from 'react';
import { GlobalContext } from 'context/GlobalContext';
import Drawer from '@mui/material/Drawer';
import style from './Sidebar.style';
import SidebarLink from 'components/core/links/SidebarLink/SidebarLink';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SuperAdminLinks from 'components/core/navigation/user-links/super-admin/SuperAdminLinks';
import AdminLinks from 'components/core/navigation/user-links/admin/AdminLinks';

import { userTypes } from 'lib/permissions';

const Sidebar = () => {
  const globalContext = useContext(GlobalContext);
  const { user } = globalContext;
  return (
    <Drawer sx={style.drawer} variant="permanent" anchor="left">
      {user.type === userTypes.SUPER_ADMIN && <SuperAdminLinks />}
      {user.type === userTypes.ADMIN && <AdminLinks />}
      <SidebarLink
        to="/logout"
        text="Logout"
        cy="logout"
        Icon={LogoutOutlinedIcon}
      />
    </Drawer>
  );
};

Sidebar.propTypes = {};
export default Sidebar;
