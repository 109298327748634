import React, { useContext } from 'react';
import { SelfAssessmentContext } from 'context/SelfAssessmentContext/SelfAssessmentContext';
import Box from '@mui/material/Box';
import Paging from 'components/context-aware/Self-Asssessment-Paging/Paging';
import Description from 'components/core/typography/description/Description';
import InputField from 'components/core/form/InputFlat/InputFlat';
import Checkbox from 'components/core/form/Checkbox/Checkbox';
import styles from './Contact.style';
const ContactFrom = () => {
  const selfAssessmentContext = useContext(SelfAssessmentContext);
  return (
    <Box data-cy="page-contact">
      <Description>
        Before you start please help us understand who you are by entering some
        details.
      </Description>
      <InputField
        cy="name"
        required
        label="Full Name"
        value={selfAssessmentContext.contactData.name}
        inputProps={{ maxLength: 500 }}
        onChange={(e) => {
          selfAssessmentContext.clearContactError('name');
          selfAssessmentContext.contactDataHandler(e.target.value, 'name');
        }}
        error={selfAssessmentContext.contactErrorState.name ? true : false}
        helperText={selfAssessmentContext.contactErrorState.name}
      />
      <InputField
        required
        cy="organisation"
        label="Organisation"
        value={selfAssessmentContext.contactData.organisation}
        inputProps={{ maxLength: 500 }}
        onChange={(e) => {
          selfAssessmentContext.clearContactError('organisation');
          selfAssessmentContext.contactDataHandler(
            e.target.value,
            'organisation',
          );
        }}
        error={
          selfAssessmentContext.contactErrorState.organisation ? true : false
        }
        helperText={selfAssessmentContext.contactErrorState.organisation}
      />
      <InputField
        required
        cy="role"
        label="Role"
        value={selfAssessmentContext.contactData.position}
        inputProps={{ maxLength: 255 }}
        onChange={(e) => {
          selfAssessmentContext.clearContactError('position');
          selfAssessmentContext.contactDataHandler(e.target.value, 'position');
        }}
        error={selfAssessmentContext.contactErrorState.position ? true : false}
        helperText={selfAssessmentContext.contactErrorState.position}
      />
      <InputField
        required
        label="Email"
        cy="email"
        value={selfAssessmentContext.contactData.email}
        inputProps={{ maxLength: 255 }}
        onChange={(e) => {
          selfAssessmentContext.clearContactError('email');
          selfAssessmentContext.contactDataHandler(e.target.value, 'email');
        }}
        error={selfAssessmentContext.contactErrorState.email ? true : false}
        helperText={selfAssessmentContext.contactErrorState.email}
      />
      <InputField
        required
        cy="tel"
        label="Contact Number"
        value={selfAssessmentContext.contactData.tel}
        inputProps={{ maxLength: 50 }}
        onChange={(e) => {
          selfAssessmentContext.clearContactError('tel');
          selfAssessmentContext.contactDataHandler(e.target.value, 'tel');
        }}
        error={selfAssessmentContext.contactErrorState.tel ? true : false}
        helperText={selfAssessmentContext.contactErrorState.tel}
      />
      <Checkbox
        cy="privacy"
        error={selfAssessmentContext.contactErrorState.privacy ? true : false}
        helperText={selfAssessmentContext.contactErrorState.privacy}
        containerStyles={styles.checkbox}
        required
        defaultChecked={selfAssessmentContext.acceptPrivacy}
        value={selfAssessmentContext.acceptPrivacy}
        onChange={(e) => {
          selfAssessmentContext.clearContactError('privacy');
          selfAssessmentContext.updateAcceptPrivacy(e.target.checked);
        }}
        label={
          <>
            {' '}
            By entering your data, you are agreeing to our data processing
            terms, as set out in RIA’s Privacy Policy:{' '}
            <a
              target="_blank"
              href="https://www.riagb.org.uk/unlockinginnovation/PrivacyPolicy"
              rel="noreferrer"
            >
              https://www.riagb.org.uk/unlockinginnovation/PrivacyPolicy
            </a>
          </>
        }
      />
      <Paging
        showBack={false}
        nextLabel="Get started"
        forwardHandler={selfAssessmentContext.validateContactData}
      />
    </Box>
  );
};

ContactFrom.propTypes = {};
export default ContactFrom;
