import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import styles from './Checkbox.style';
const CheckboxField = ({
  id,
  cy,
  label,
  inputProps,
  required,
  sx,
  helperText,
  error,
  onChange,
  disabled,
  defaultChecked,
  formLabelSx,
  value,
  containerStyles,
}) => {
  return (
    <FormControl error={error} sx={containerStyles}>
      <FormControlLabel
        disabled={disabled}
        sx={{ ...styles, ...formLabelSx }}
        control={
          <Checkbox
            id={id}
            data-cy={cy}
            sx={sx}
            defaultChecked={defaultChecked}
            value={value}
            onChange={onChange}
            required={required}
          />
        }
        label={label}
      />
      <FormHelperText sx={styles.helperText} data-cy={`field-error-text-${cy}`}>
        {' '}
        {helperText}{' '}
      </FormHelperText>
    </FormControl>
  );
};

CheckboxField.defaultProps = {
  required: undefined,
  inputProps: undefined,
  sx: undefined,
  error: false,
  helperText: undefined,
  onChange: undefined,
  disabled: false,
  autoComplete: undefined,
  formLabelSx: undefined,
  containerStyles: undefined,
  defaultChecked: undefined,
};

CheckboxField.propTypes = {
  id: PropTypes.string,
  cy: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  required: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  formLabelSx: PropTypes.shape({}),
  containerStyles: PropTypes.shape({}),
  defaultChecked: PropTypes.bool,
};
export default CheckboxField;
