import AuthenticationError from '../../lib/errors/authentication-error';
import HTTPError from '../../lib/errors/http-error';
import api from '../../lib/http';

const login = async (email, password) => {
  const response = await api(
    'post',
    'auth/login',
    { email, password },
    false,
    false,
    true,
  );

  if (!response.ok) {
    if (response.status === 401) {
      throw new AuthenticationError();
    } else {
      throw new HTTPError(response.status, response.data);
    }
  }

  return response;
};

const resetPassword = async (email) => {
  const result = await api(
    'post',
    'auth/password-reset',
    { email },
    true,
    false,
    true,
  );
  return result.data;
};

const newPassword = async (token, password) => {
  const result = await api(
    'post',
    'auth/new-password',
    { token, password },
    true,
    false,
    true,
  );
  return result.data;
};

const validatePasswordResetToken = async (token) => {
  const result = await api(
    'get',
    'auth/validate-password-reset-token',
    { token },
    false,
    false,
    true,
  );

  if (!result.ok) {
    throw new HTTPError(result.status, result.data);
  }

  return result.data;
};

const authService = {
  login,
  resetPassword,
  newPassword,
  validatePasswordResetToken,
};

export default authService;
