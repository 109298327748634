import React, { useState, useReducer } from 'react';
import reducers from 'reducers/form-errors-reducer';
import PropTypes from 'prop-types';
import Intro from 'components/context-aware/Self-Assessment-Pages/Intro/Intro';
import ContactFrom from 'components/context-aware/Self-Assessment-Pages/ContactForm/ContactForm';
import Question from 'components/context-aware/Self-Assessment-Pages/Question/Question';
import Results from 'components/context-aware/Self-Assessment-Pages/Results/Results';
import emptyStringValidator from 'lib/validators/empty-string';
import emailValidatorUtil from 'lib/validators/email';
import questions from './questions.schema';
import sendContactMail from 'services/contact/contact-service';
import shapeScores from './shapeScores';
const scoreObject = {
  categories: [
    ['Know your industry'],
    ['Know your', 'customer'],
    ['Know your customer’s', 'acceptance process'],
    ['Know your', 'technology'],
    ['Know your', 'capacity'],
  ],
  currentScore: [],
  requiredScore: [],
  interval: 1,
  max: 7,
  min: 0,
};

const stepsSchema = [
  { id: 1, component: <Intro /> },
  { id: 2, component: <ContactFrom /> },
  { id: 3, component: <Question questionId={1} questionData={questions[1]} /> },
  { id: 4, component: <Question questionId={2} questionData={questions[2]} /> },
  { id: 5, component: <Question questionId={3} questionData={questions[3]} /> },
  {
    id: 6,
    component: <Question questionId={4} questionData={questions[4]} />,
  },
  {
    id: 7,
    component: (
      <Question
        questionId={5}
        questionData={questions[5]}
        nextLabel={'Get Results'}
        complete
      />
    ),
  },
  { id: 8, component: <Results /> },
];

const initContactData = {
  name: '',
  organisation: '',
  tel: '',
  position: '',
  email: '',
};

const CONTACT_FIELDS = Object.keys(initContactData);

const initialContactErrorState = {
  name: null,
  organisation: null,
  tel: null,
  position: null,
  email: null,
  privacy: null,
};

export const SelfAssessmentContext = React.createContext({
  contactData: initContactData,
  index: 1,
  resultsData: {},
  stepsSchema: [],
});

const SelfAssessmentContextProvider = ({ children }) => {
  const [contactData, updateContactData] = useState(initContactData);
  const [acceptPrivacy, updateAcceptPrivacy] = useState(false);
  const [index, updateIndex] = useState(1);
  const [resultData, updateResultData] = useState(scoreObject);
  const [currentScores, updateCurrentScores] = useState({});
  const [requiredScores, updateRequiredScores] = useState({});
  const [contactErrorState, contactErrorDispatch] = useReducer(
    reducers.formErrorsReducer,
    initialContactErrorState,
  );

  const contactDataHandler = (value, field) => {
    const updatedData = { ...contactData, [field]: value };
    updateContactData(updatedData);
  };

  const validateContactData = () => {
    const clientErrors = [];
    for (let field of CONTACT_FIELDS) {
      if (emptyStringValidator(contactData[field])) {
        clientErrors.push(field);
      }
    }

    if (!clientErrors.find((field) => field === 'email')) {
      const isValidEmail = emailValidatorUtil(contactData.email);
      if (!isValidEmail) {
        return reducers.createFormErrorsHandler(
          'email',
          'email address must be valid',
          contactErrorDispatch,
        );
      }
    }

    if (!acceptPrivacy) {
      return reducers.createFormErrorsHandler(
        'privacy',
        'you must accept the privacy policy before proceeding',
        contactErrorDispatch,
      );
    }

    if (clientErrors.length) {
      for (let field of clientErrors) {
        reducers.createFormErrorsHandler(
          field,
          'is a required field',
          contactErrorDispatch,
        );
      }
    } else {
      updateIndex(index + 1);
    }
  };

  const updateSelection = (questionId, answerId, requiredValue = false) => {
    if (requiredValue) {
      const updatedRequiredScore = {
        ...requiredScores,
        [questionId]: answerId,
      };
      updateRequiredScores(updatedRequiredScore);
    } else {
      const updatedCurrentScore = {
        ...currentScores,
        [questionId]: answerId,
      };
      updateCurrentScores(updatedCurrentScore);
    }
  };

  const sendContactEmail = () => sendContactMail(contactData);

  const onCompleteHandler = async () => {
    const updatedResults = { ...resultData };
    updatedResults.currentScore = shapeScores(currentScores);
    updatedResults.requiredScore = shapeScores(requiredScores);
    updateResultData(updatedResults);
  };

  const updatePageIndex = (forward = true) => {
    if (forward) {
      updateIndex(index + 1);
    } else {
      updateIndex(index - 1);
    }
  };

  return (
    <SelfAssessmentContext.Provider
      value={{
        contactData: contactData,
        index: index,
        stepsSchema,
        updateIndex,
        validateContactData,
        updatePageIndex,
        contactDataHandler,
        contactErrorState,
        acceptPrivacy,
        updateAcceptPrivacy,
        onCompleteHandler,
        currentScores,
        requiredScores,
        updateSelection,
        sendContactEmail,
        resultData,
        clearContactError: (field) =>
          reducers.clearFieldError(
            field,
            contactErrorState,
            contactErrorDispatch,
          ),
      }}
    >
      {children}
    </SelfAssessmentContext.Provider>
  );
};

SelfAssessmentContextProvider.propTypes = {
  children: PropTypes.node,
};

export default SelfAssessmentContextProvider;
