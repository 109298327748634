const formErrorsReducer = (state, action) => {
  switch (action.type) {
    case 'CREATE_ERROR':
      return {
        ...state,
        [action.field]: action.payload,
      };
    case 'CLEAR_ERROR':
      return {
        ...state,
        [action.field]: null,
      };
    default:
      return state;
  }
};

const createFormErrorsHandler = (fieldName, errorMessage, dispatch) =>
  dispatch({
    type: 'CREATE_ERROR',
    field: fieldName,
    payload: errorMessage,
  });

const clearFieldError = (fieldName, state, dispatch) => {
  if (state[fieldName]) {
    dispatch({
      type: 'CLEAR_ERROR',
      field: fieldName,
    });
  }
};

const reducers = {
  formErrorsReducer,
  createFormErrorsHandler,
  clearFieldError,
};

export default reducers;
