import React from 'react';
import styles from './InnovationLandscape.style';
import { AppBar, Container, Grid, Toolbar } from '@mui/material';
import { Box } from '@mui/system';
import Paragraph from 'components/core/typography/paragraph/Paragraph';
import InnovationLinkGroup from '../../components/custom/InnovationLinkGroup';
import innovationLinks from './links.schema';
import Pill from 'components/core/buttons/Pill/Pill';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';

const InnovationLandscape = () => {
  const onClick = () =>
    (window.location.href =
      'https://www.riagb.org.uk/UnlockingInnovation/Navigator/Unlocking_Innovation/Navigator/UINavigator.aspx');
  return (
    <React.Fragment>
      <Box sx={{ ...styles.page }}>
        <AppBar position="relative" sx={{ ...styles.appBar }}>
          <Toolbar>
            <Paragraph sx={{ ...styles.headingPage, flexGrow: 1 }}>
              The Innovation Landscape
            </Paragraph>
            <Pill
              label="Back to Unlocking Innovation"
              endIcon={<NavigateNextRoundedIcon />}
              naked
              sx={styles.pillButton}
              onClick={onClick}
            />
          </Toolbar>
        </AppBar>
        <Container sx={styles.container}>
          <Grid
            container
            spacing={4}
            justifyContent="center"
            alignContent="flex-start"
          >
            {innovationLinks.map((group) => {
              return (
                <Grid item>
                  <InnovationLinkGroup group={group} />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};

const InnovationLandscapePage = () => {
  return <InnovationLandscape />;
};
export default InnovationLandscapePage;
