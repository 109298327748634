import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { SelfAssessmentContext } from 'context/SelfAssessmentContext/SelfAssessmentContext';
import Paging from 'components/context-aware/Self-Asssessment-Paging/Paging';
import styles from './Question.style';
import Description from 'components/core/typography/description/Description';
import HTMLViewer from 'components/core/html-viewer/HTMLViewer';
import NakedCheckbox from 'components/core/form/Checkbox/NakedCheckBox';
import Paragraph from 'components/core/typography/paragraph/Paragraph';
import BasicTooltip from 'components/core/tooltip/Tooltip';

const Question = ({ questionId, nextLabel, questionData, complete }) => {
  const selfAssessmentContext = useContext(SelfAssessmentContext);

  return (
    <Box sx={{ flexGrow: 1 }} data-cy={`page-question-${questionId}`}>
      <Box sx={styles.header}>
        <Box sx={styles.questonNumber}>{questionId}</Box>
      </Box>
      <Description>{questionData.question}</Description>
      <Box>
        <Grid container spacing={2} sx={styles.container}>
          <Grid item xs={9}></Grid>
          <Grid item xs={1.5} sx={styles.columnLabel}>
            <BasicTooltip
              title="Respond to the statements in this column by considering where you currently are."
              placement="top-start"
              label="Current Understanding"
            />
          </Grid>
          <Grid item xs={1.5} sx={styles.columnLabel}>
            <BasicTooltip
              title="Respond to the statements in this column by considering where you would like to be to deliver your innovation."
              placement="top-start"
              label="Desired Understanding"
            />
          </Grid>
        </Grid>
        {questionData.answers.map((question) => {
          return (
            <Grid container spacing={2} sx={styles.container} key={question.id}>
              <Grid item xs={9} sx={styles.label}>
                <HTMLViewer html={question.label} className="question" />
              </Grid>
              <Grid item xs={1.5}>
                <Box sx={styles.checkboxCurrent}>
                  <NakedCheckbox
                    cy={`checkbox-current-${questionId}-${question.id}`}
                    checked={
                      selfAssessmentContext.currentScores[questionId] ===
                      question.id
                        ? true
                        : false
                    }
                    onChange={() =>
                      selfAssessmentContext.updateSelection(
                        questionId,
                        question.id,
                      )
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={1.5}>
                <Box sx={styles.checkboxRequired}>
                  <NakedCheckbox
                    cy={`checkbox-required-${questionId}-${question.id}`}
                    checked={
                      selfAssessmentContext.requiredScores[questionId] ===
                      question.id
                        ? true
                        : false
                    }
                    onChange={() =>
                      selfAssessmentContext.updateSelection(
                        questionId,
                        question.id,
                        true,
                      )
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </Box>
      <Box sx={styles.helperText}>
        <Paragraph>
          *You must select one answer from each column to continue.
        </Paragraph>
      </Box>
      <Paging
        backStyles={styles.back}
        showBack={questionData.showBack}
        forwardHandler={() => {
          if (complete) {
            selfAssessmentContext.onCompleteHandler();
          }
          selfAssessmentContext.updatePageIndex();
        }}
        disableForward={
          selfAssessmentContext.currentScores[questionId] &&
          selfAssessmentContext.requiredScores[questionId]
            ? false
            : true
        }
        backLabel={'Previous Question'}
        nextLabel={nextLabel}
        backHandler={() => selfAssessmentContext.updatePageIndex(false)}
      />
    </Box>
  );
};

Question.defaultProps = {
  nextLabel: 'Next Question',
  questionData: undefined,
  complete: false,
};
Question.propTypes = {
  questionId: PropTypes.number.isRequired,
  nextLabel: PropTypes.string,
  questionData: PropTypes.shape({}),
  complete: PropTypes.bool,
};
export default Question;
