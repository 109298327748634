import React, { useContext } from 'react';
import { SelfAssessmentContext } from 'context/SelfAssessmentContext/SelfAssessmentContext';
import Paging from 'components/context-aware/Self-Asssessment-Paging/Paging';
import Heading from 'components/core/typography/headings/large/HeadingLarge';
import Paragraph from 'components/core/typography/paragraph/Paragraph';
import styles from './Intro.style';
import HeadingMedium from 'components/core/typography/headings/medium/HeadingMedium';
// import Img from "components/core/img/Img";
// import spiderDiagram from "assets/navigator-self-assessment.svg";
const Intro = () => {
  const selfAssessmentContext = useContext(SelfAssessmentContext);
  return (
    <div data-cy="page-intro">
      <Heading sx={styles.heading} cy="intro-heading">
        Assess Your Readiness Level
      </Heading>
      <HeadingMedium sx={styles.subHeading} cy="self-assessment-intro-heading">
        Introduction
      </HeadingMedium>
      <Paragraph>
        Railways are vital to the UK’s prosperity. They support economic growth,
        jobs and investment, connect communities around the country and provide
        a low carbon form of mass transport for both people and goods.
        Innovation is an essential part of ensuring our railways continue to
        deliver. In fact, innovation is a necessity if we are to make life
        better for customers, both passengers and freight users, and to make the
        railways financially and environmentally sustainable in the longer term.
      </Paragraph>
      <Paragraph>
        But innovation is not straightforward. For companies, progressing an
        idea can mean a significant investment, for which the returns are not
        guaranteed. There are also many unknowns too, especially if the SME is
        new to the railway industry.
      </Paragraph>
      <Paragraph>
        Innovation is about much more than technology development. It includes
        the regulatory, commercial and operational environment in which
        development and deployment takes place. This Self-Assessment Tool
        encourages a broad approach to innovation. It supports firms that are
        looking beyond their underlying technology in order to develop a wider
        understanding of what is needed to progress from an idea to a new
        product or service that is commercially viable.
      </Paragraph>
      <HeadingMedium
        sx={styles.subHeading}
        cy="self-assessment-about-assessment"
      >
        This Self-Assessment Tool
      </HeadingMedium>
      <Paragraph>
        This Self-Assessment Tool has been developed to help businesses and
        individuals innovate successfully in the UK’s railway industry. It will
        help you understand what you need to do, where your strengths are, and
        where you need to focus your efforts.{' '}
      </Paragraph>
      <Paragraph>
        Using this Tool will help you as an innovator to make the most of your
        great idea and help the industry deliver the service and performance
        that its customers expect. This guide has been built on the experiences
        of companies who have progressed innovations in the industry, both those
        who have been successful and those who have faced difficulties, as well
        as relevant good practice.{' '}
      </Paragraph>
      <Paragraph>
        Ultimately, by using this Tool, we hope you will get an understanding of
        the issues that need to be addressed at each phase of the innovation
        process so that your firm is well placed to reap the rewards of
        introducing new products and services into the rail industry, whether
        you're new or well-established in the sector.{' '}
      </Paragraph>
      <HeadingMedium sx={styles.subHeading} cy="self-assessment-how-to-use">
        How to use the Self-Assessment Tool
      </HeadingMedium>
      <Paragraph>
        In the following pages, you will be asked a number of multiple-choice
        questions. Please answer these as honestly as you can, clicking the
        answer that best represents your organisation’s situation. You have the
        opportunity to answer each question twice, once from the perspective of
        your current position and secondly from the perspective of where you
        think you will need to be in order to perform well, or your required
        level. In other words, your ‘as-is’ and ‘where you want to be’ level of
        innovation.
      </Paragraph>
      <Paragraph>
        After filling in the questions, you will be presented with a
        spider-diagram with your ‘where you currently are’ and ‘where you need
        to be' scores helping you to understand your ‘innovation readiness’ and
        further information is available on boosting your readiness in specific
        areas. This spider-diagram can be saved or printed out, and is private
        to you – the information on it will not be saved, or shared by RIA in
        any capacity.{' '}
      </Paragraph>
      <Paragraph>
        <strong>Acknowledgements:</strong> The Self-Assessment Tool is based on
        the document ‘Enabling innovation in the rail industry’ produced in 2012
        by RSSB for the Technical Strategy Leadership Group and the Railway
        Industry Association (RIA). With thanks to Frazer-Nash Consultancy, RSSB
        and Network Rail for their contributions to the Tool.{' '}
      </Paragraph>
      <Paging
        nextLabel="Let's get started"
        showBack={false}
        forwardHandler={() =>
          selfAssessmentContext.updateIndex(selfAssessmentContext.index + 1)
        }
      />
    </div>
  );
};

Intro.propTypes = {};
export default Intro;
