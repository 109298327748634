import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import styles from './RadarGraph.styles';
const RadarGraph = ({ options, series, sx, cy, width, height }) => {
  return (
    <Box sx={{ ...styles, ...sx }} data-cy={cy}>
      <Chart
        options={{
          ...options,
        }}
        width={width}
        height={height}
        series={series}
        type="radar"
      />
    </Box>
  );
};

RadarGraph.defaultProps = {
  width: '100%',
};

RadarGraph.propTypes = {
  options: PropTypes.shape({
    chart: PropTypes.shape({
      id: PropTypes.string,
    }),
    width: PropTypes.number,
    height: PropTypes.number,
    xaxis: PropTypes.shape({
      categories: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      ),
    }),
    yaxis: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
      tickAmont: PropTypes.number,
    }),
  }),
  series: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.number),
    }),
  ),

  sx: PropTypes.shape({}),
  cy: PropTypes.string,
};
export default RadarGraph;
