import React from 'react';
import { Route, Switch } from 'react-router-dom';

import SuperAdminDashboard from 'pages/dashboards/super-admin/SuperAdminDashboard';
import LogoutPage from 'pages/authentication/logout-page/LogoutPage';
import NoRouteFound from 'pages/errors/LoggedIn/404';

const SuperAdminRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={SuperAdminDashboard} />
      <Route exact path="/logout" component={LogoutPage} />
      <Route path="*" component={NoRouteFound} />
    </Switch>
  );
};

SuperAdminRoutes.propTypes = {};
export default SuperAdminRoutes;
