import stylesConfig from 'theme/config';

const styles = {
  container: {
    background: stylesConfig.colors.secondary,
    padding: stylesConfig.spacing.small,
    borderRadius: '6px',
    marginBottom: stylesConfig.spacing.veryLarge,
    '.MuiTypography-p': {
      color: stylesConfig.colors.white,
      margin: 0,
      fontWeight: 500,
    },
  },
};

export default styles;
