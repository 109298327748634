import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import styles from './BasicPageLayout.style';

const BasicPageLayout = ({ children }) => {
  return (
    <Box sx={styles.page}>
      <Box sx={styles.backPlate}>
        <Box sx={styles.headerPanel}></Box>
        <Box sx={styles.contentPanel}></Box>
        <Box sx={styles.contentPanel}></Box>
        <Box sx={styles.contentPanel}></Box>
        <Box sx={styles.footerPanel}></Box>
      </Box>
      <Box sx={styles.container}>
        <Box sx={styles.wrapper}>{children}</Box>
      </Box>
    </Box>
  );
};

BasicPageLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
export default BasicPageLayout;
