import stylesConfig from 'theme/config';

const styles = {
  heading: {
    font: 'normal normal bold 16px/19px Montserrat;',
    letterSpacing: '0',
    color: stylesConfig.colors.description,
  },
};

export default styles;
