import React, { useContext } from 'react';

import { GlobalContext } from '../../../context/GlobalContext';

const LogoutPage = () => {
  const { logout } = useContext(GlobalContext);
  logout();

  return <div>Logging Out ...</div>;
};

export default LogoutPage;
