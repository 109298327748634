import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import InternalLink from 'components/core/links/Internal/InternalLink';
import styles from './SidebarLink.style';

const SidebarLink = ({ to, text, Icon, cy }) => {
  const location = useLocation();
  let active = {};
  if (location.pathname === to) {
    active = styles.active;
  }
  return (
    <Box sx={{ ...styles.container, ...active }}>
      <InternalLink to={to} style={styles.link} cy={cy}>
        <Box sx={styles.icon}>{Icon && <Icon />}</Box>
        {text}
      </InternalLink>
    </Box>
  );
};

SidebarLink.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string,
  Icon: PropTypes.shape({}),
  cy: PropTypes.string,
};
export default SidebarLink;
