import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

const HTMLViewer = ({ html, className }) => {
  return (
    <div
      className={className}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(html, { ADD_ATTR: ['target'] }),
      }}
    />
  );
};

HTMLViewer.defaultProps = {
  className: '',
};

HTMLViewer.propTypes = {
  html: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default HTMLViewer;
