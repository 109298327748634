import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

const TextInputField = ({
  id,
  cy,
  label,
  variant,
  inputProps,
  required,
  sx,
  helperText,
  error,
  onChange,
  type,
  defaultValue,
  disabled,
  autoComplete,
  value,
  multiline,
  disableUnderline,
}) => {
  return (
    <TextField
      id={id}
      defaultValue={defaultValue}
      helperText={helperText}
      error={error}
      autoComplete={autoComplete}
      data-cy={cy}
      value={value}
      required={required}
      inputProps={inputProps}
      label={label}
      variant={variant}
      onChange={onChange}
      sx={{ marginBottom: '1rem', ...sx }}
      type={type}
      disabled={disabled}
      multiline={multiline}
      InputProps={{ disableUnderline }}
    />
  );
};

TextInputField.defaultProps = {
  variant: 'standard',
  required: undefined,
  inputProps: undefined,
  sx: undefined,
  error: false,
  helperText: undefined,
  onChange: undefined,
  type: 'text',
  defaultValue: undefined,
  disabled: false,
  autoComplete: undefined,
  value: undefined,
  multiline: false,
  disableUnderline: false,
};

TextInputField.propTypes = {
  id: PropTypes.string,
  cy: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  value: PropTypes.string,
  multiline: PropTypes.bool,
  disableUnderline: PropTypes.bool,
};
export default TextInputField;
