import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { GlobalContext } from 'context/GlobalContext';
import SuperAdminRoutes from './user-routes/super-admin/SuperAdminRoutes';
import NoRouteFound from 'pages/errors/LoggedIn/404';
import LogoutPage from 'pages/authentication/logout-page/LogoutPage';
import { userTypes } from 'lib/permissions';
import AdminUserRoutes from './user-routes/admin/AdminRoutes';

export const LoggedInRoutes = () => {
  const globalContext = useContext(GlobalContext);
  const { user } = globalContext;
  return (
    <>
      <Switch>
        {user.type === userTypes.SUPER_ADMIN && <SuperAdminRoutes />}
        {user.type === userTypes.ADMIN && <AdminUserRoutes />}
        <Route exact path="/logout" component={LogoutPage} />
        <Route path="*" component={NoRouteFound} />
      </Switch>
    </>
  );
};
