import stylesConfig from 'theme/config';

const panelStyles = {
  width: '100%',
};

const styles = {
  page: {
    minHeight: '400px',
    height: '100vh',
    width: '100%',
    display: 'flex',
  },
  container: {
    maxWidth: {
      xs: '750px',
    },
    background: stylesConfig.colors.white,
    height: '100%',
    paddingLeft: '15px',
    paddingRight: '15px',
    margin: '0 auto',
    overflowX: 'scroll',
  },
  wrapper: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    minHeight: '100%',
  },
  backPlate: {
    position: 'fixed',
    width: '105%',
    height: '105%',
    top: '-8px',
    right: '-8px',
    zIndex: -1,
    background: stylesConfig.colors.toupe,
    display: 'flex',
    flexDirection: 'column',
    filter: 'blur(16px)',
    justifyContent: 'space-between',
  },
  headerPanel: {
    ...panelStyles,
    height: '25%',
    background: stylesConfig.colors.accent,
  },
  contentPanel: {
    ...panelStyles,
    height: '10%',
    background: stylesConfig.colors.grey,
  },
  footerPanel: {
    ...panelStyles,
    height: '20%',
    background: stylesConfig.colors.secondary,
  },
};

export default styles;
