import React from 'react';
import LoggedIn from 'components/layouts/logged-in/LoggedIn';
import InternalLink from 'components/core/links/Internal/InternalLink';
import HeadingLarge from 'components/core/typography/headings/large/HeadingLarge';
import Paragraph from 'components/core/typography/paragraph/Paragraph';

const HomePage = () => {
  return (
    <LoggedIn>
      <HeadingLarge text="404" />
      <Paragraph cy="404-error-blurb" text={"This page doesn't exist."} />
      <Paragraph>
        <InternalLink to={'/'} text={'Home'} cy="home" />
      </Paragraph>
    </LoggedIn>
  );
};

export default HomePage;
