import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LoggedIn from 'components/layouts/logged-in/LoggedIn';

const HomePage = () => {
  return (
    <LoggedIn heading="Home">
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}></Grid>
        </Box>
      </Box>
    </LoggedIn>
  );
};

export default HomePage;
