import api from '../../lib/http';

const selfAssessmentContact = async (payload = {}) => {
  const response = await api(
    'post',
    'submissions/contact',
    payload,
    false,
    false,
    true,
  );

  return response;
};

export default selfAssessmentContact;
