import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import styles from './Tooltip.style';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

const BasicTooltip = ({ title, placement, label, sx }) => {
  return (
    <Tooltip title={title} placement={placement}>
      <Stack justifyContent="center" alignItems="center" spacing={0}>
        <InfoOutlinedIcon
          fontSize="small"
          sx={{ color: styles.icon.color, ...sx }}
        />
        <Box textAlign="center" sx={{ ...styles.button }}>
          {label}
        </Box>
      </Stack>
    </Tooltip>
  );
};

BasicTooltip.defaultProps = {
  title: '',
  placement: '',
  label: '',
  sx: undefined,
};

BasicTooltip.propTypes = {
  title: PropTypes.string,
  placement: PropTypes.string,
  label: PropTypes.string,
  sx: PropTypes.shape({}),
};
export default BasicTooltip;
