// import stylesConfig from "theme/config";

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export default styles;
