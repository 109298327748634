import stylesConfig from 'theme/config';

const styles = {
  heading: {
    font: 'normal normal 22px/27px Montserrat',
    letterSpacing: '0.18px',
    color: stylesConfig.colors.black,
  },
};

export default styles;
