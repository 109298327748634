import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box/Box';
import styles from './InnovationLink.style';
import { Grid, Link } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
const InnovationLink = ({ text, cy, href, sx }) => {
  return (
    <Box data-cy={cy} sx={{ ...styles.container, ...sx }}>
      <Link
        href={href}
        target="_blank"
        underline="none"
        sx={{ ...styles.link }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          padding={1}
        >
          <Grid item xs={10}>
            {text}
          </Grid>
          <Grid item direction={'column'} alignContent="stretch">
            <ArrowForwardIosRoundedIcon />
          </Grid>
        </Grid>
      </Link>
    </Box>
  );
};

InnovationLink.defaultProps = {
  cy: 'link',
};

InnovationLink.propTypes = {
  text: PropTypes.string,
  cy: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
export default InnovationLink;
