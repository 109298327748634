import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import styles from './Pill.style';

const Pill = ({
  label,
  sx,
  onClick,
  startIcon,
  endIcon,
  disabled,
  naked,
  theme,
  cy,
}) => {
  let propStyles = {};

  if (theme === 'secondary') {
    propStyles = { ...styles.secondary };
  }

  if (naked) {
    propStyles = styles.naked[theme];
  }

  return (
    <Button
      data-cy={cy}
      disabled={disabled}
      type="button"
      onClick={onClick}
      sx={{ ...styles.default, ...propStyles, ...sx }}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {label}
    </Button>
  );
};
Pill.defaultProps = {
  theme: 'primary',
  cy: 'button-pill',
};
Pill.propTypes = {
  label: PropTypes.string,
  sx: PropTypes.shape({}),
  theme: PropTypes.oneOf(['primary', 'secondary']),
  cy: PropTypes.string,
};
export default Pill;
