import React from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
// import { PermissionsGated } from "components/context-aware/PermissionsGated/PermissionsGated";
// import Permissions from "lib/permissions";
import SidebarLink from 'components/core/links/SidebarLink/SidebarLink';

const SuperAdminLinks = () => {
  return (
    <>
      <SidebarLink
        to="/"
        text="Home"
        Icon={HomeOutlinedIcon}
        cy="super-admin-home"
      />
    </>
  );
};

SuperAdminLinks.propTypes = {};
export default SuperAdminLinks;
