import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import styles from './NakedCheckbox.style';
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
const NakedCheckbox = ({ checked, onChange, ariaLabel, sx, icon, cy }) => {
  return (
    <Checkbox
      data-cy={cy}
      sx={{ ...styles, ...sx }}
      checked={checked}
      onChange={onChange}
      inputProps={{ 'aria-label': ariaLabel }}
      icon={icon}
      indeterminateIcon={<DoneSharpIcon />}
    />
  );
};

NakedCheckbox.defaultProps = {
  checked: false,
  onChange: () => {},
  ariaLabel: '',
  icon: undefined,
  cy: 'naked-checkbox',
};

NakedCheckbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  ariaLabel: PropTypes.string,
  cy: PropTypes.string,
};
export default NakedCheckbox;
