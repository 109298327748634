import questions from './questions.schema';

const shapeScores = (scores) => {
  const idsArray = Object.values(scores);
  return idsArray.map((answerId, index) => {
    const answer = questions[index + 1].answers.find(
      (answer) => answer.id === answerId,
    );
    return answer.value;
  });
};

export default shapeScores;
