import stylesConfig from 'theme/config';
import styleConfig from 'theme/config';
const styles = {
  heading: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: styleConfig.spacing.standard,
    color: styleConfig.colors.secondary,
  },
  subHeading: {
    color: styleConfig.colors.secondary,
    marginBottom: styleConfig.spacing.standard,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: stylesConfig.spacing.veryLarge,
  },
  container: {
    justifyContent: 'center',
  },
  graph: {
    boxShadow: '0px 3px 6px #00000029',
    marginBottom: stylesConfig.spacing.large,
  },
  pillButton: {},
};

export default styles;
