import stylesConfig from 'theme/config';

const styles = {
  heading: {
    color: stylesConfig.colors.secondary,
  },
  subHeading: {
    color: stylesConfig.colors.primary,
    marginBottom: stylesConfig.spacing.standard,
  },
};
export default styles;
