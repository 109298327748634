module.exports.activities = {
  SUPER_ADMIN_DASHBOARD: 'SUPER_ADMIN_DASHBOARD',
  SUPER_ADMIN_USER_MANAGEMENT: 'SUPER_ADMIN_USER_MANAGEMENT',
  ADMIN_DASHBOARD: 'ADMIN_DASHBOARD',
  ADMIN_USER_MANAGEMENT: 'ADMIN_USER_MANAGEMENT',
  COLLABORATOR_DASHBOARD: 'COLLABORATOR_DASHBOARD',
  SURVEY_CREATION: 'SURVEY_CREATION',
  SURVEY_COMPLETION: 'SURVEY_COMPLETION',
  VIEW_SURVEY: 'VIEW_SURVEY',
  COMPANY_MANAGEMENT: 'COMPANY_MANAGEMENT',
  ADMIN_COMPANY_MANAGEMENT: 'ADMIN_COMPANY_MANAGEMENT',
};

module.exports.doesUserHaveActivities = (
  userActivityList,
  requiredActivities,
) => {
  if (!userActivityList || !Array.isArray(userActivityList)) {
    return false;
  }

  let hasActivity = true;
  requiredActivities.forEach((activity) => {
    if (!userActivityList.includes(activity)) {
      hasActivity = false;
    }
  });

  return hasActivity;
};

module.exports.userTypes = {
  SUPER_ADMIN: 'super-admin',
  ADMIN: 'admin',
  COLLABORATOR: 'collaborator',
};
