import stylesConfig from 'theme/config';

const styles = {
  container: {
    background: stylesConfig.colors.lightGrey,
    borderRadius: '8px',
    marginBottom: stylesConfig.spacing.standard,
    marginLeft: stylesConfig.spacing.small,
    marginRight: stylesConfig.spacing.small,
  },
  link: {
    width: '90%',
    color: stylesConfig.colors.secondary,
    font: 'normal normal medium 16px/16px Montserrat',
  },
};

export default styles;
