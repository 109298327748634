import React from 'react';
import { Container } from '@mui/material';
import HeadingLarge from 'components/core/typography/headings/large/HeadingLarge';
import Paragraph from 'components/core/typography/paragraph/Paragraph';
import Box from '@mui/material/Box';
import InternalLink from 'components/core/links/Internal/InternalLink';
import styles from './404.style.js';

export const LoggedOutNoRouteFound = () => {
  return (
    <Box sx={styles.page}>
      <Container sx={styles.container}>
        <HeadingLarge text="404" />
        <Paragraph text="This page doesn't exist." />
        <Paragraph>
          <InternalLink to={'/self-assessment'} text={'Home'} cy="home" />
        </Paragraph>
      </Container>
    </Box>
  );
};
