import React from 'react';
import Box from '@mui/material/Box';
import Paragraph from 'components/core/typography/paragraph/Paragraph';
import InnovationLink from './InnovationLink';
import styles from './InnovationLinkGroup.style';

const InnovationLinkGroup = (group) => {
  const item = group.group;
  return (
    <Box
      item
      sx={{
        width: '326px',
        height: '100%',
        background: 'white',
      }}
    >
      <Box sx={{ ...styles.headingBox, backgroundColor: item.headingColor }}>
        <Paragraph sx={styles.linkGroupTitle}>{item.headingText}</Paragraph>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: 1,
        }}
      >
        {item.links.map((link) => {
          return <InnovationLink text={link.label} href={link.target} />;
        })}
      </Box>
    </Box>
  );
};

export default InnovationLinkGroup;
