//import stylesConfig from "theme/config";

const styles = {
  page: {
    minHeight: '400px',
    height: '100vh',
    width: '100%',
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: { xs: '550px' },
  },
};

export default styles;
