import stylesConfig from 'theme/config';

const styles = {
  page: {
    background: stylesConfig.colors.lightGrey,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  pillButton: {
    background: stylesConfig.colors.white,
    color: stylesConfig.colors.primary,
    width: '290px',
    font: 'normal normal bold 20px/22px Montserrat',
    alignText: 'left',
    '&:hover': {
      background: stylesConfig.colors.white,
    },
  },
  appBar: {
    minHeight: '152px',
    justifyContent: 'center',
    background: stylesConfig.colors.secondary,
  },
  headingPage: {
    font: 'normal normal bold 40px/49px Montserrat',
    color: 'white',
  },
  container: {
    maxWidth: { md: '1600px', xs: '400px' },
    paddingY: '70px',
  },
  linkHeader: {
    background: stylesConfig.colors.secondary,
    padding: stylesConfig.spacing.large,
    marginBottom: stylesConfig.spacing.veryLarge,
    '.MuiTypography-p': {
      color: stylesConfig.colors.white,
      margin: 0,
      fontWeight: 800,
    },
  },
};

export default styles;
