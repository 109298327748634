import stylesConfig from 'theme/config';

const styles = {
  button: {
    font: stylesConfig.p.font,
    fontSize: '10px',
    textTransform: 'none',
    fontWeight: 'bold',
    color: stylesConfig.colors.darkGrey,
  },
  icon: {
    color: stylesConfig.colors.primary,
  },
};

export default styles;
