import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SelfAssessment from 'pages/self-assessment/SelfAssessment';
import UnderConstruction from 'pages/under-construction/UnderConstruction';
import InnovationLandscapePage from 'pages/innovation-landscape/InnovationLandscape';
import { LoggedOutNoRouteFound } from 'pages/errors/LoggedOut/404';
export const LoggedOutRoutes = () => {
  return (
    <Switch>
      <Route exact path="/self-assessment" component={SelfAssessment} />
      <Route
        exact
        path="/innovation-landscape"
        component={InnovationLandscapePage}
      />
      <Route exact path="/under-construction" component={UnderConstruction} />
      <Route path="*" component={LoggedOutNoRouteFound} />
    </Switch>
  );
};
