import stylesConfig from 'theme/config';

const drawerWidth = 328;
const styles = {
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      border: 0,
      boxSizing: 'border-box',
      background: stylesConfig.colors.primary,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    color: stylesConfig.colors.white,
    position: 'absolute',
    bottom: '60px',
    width: '100%',
  },
};

export default styles;
