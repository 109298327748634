const colors = {
  innoInclusion: '#005172',
  innoLeadership: '#106AA3',
  innoTechnology: '#41287F',
  innoFunding: '#7F1F7D',
  innoRandD: '#8B60A0',
  innoCross: '#AE174D',
  innoAdvice: '#C94578',
  innoIndustry: '#C94043',
  innoTesting: '#70B397',
  innoSkills: '#1D7880',
  innoStructure: '#51ACB8',
  innoDev: '#4E9643',
};

const innovationLinks = [
  {
    headingText: 'Inclusion',
    headingColor: colors.innoInclusion,
    links: [
      {
        label: 'Trailblazers',
        target:
          'https://www.riagb.org.uk/RIA/AboutUs/Equality__Diversity___Inclusion/RIA/Who_we_are/Equality_Diversity_Inclusion.aspx',
      },
      {
        label: 'Women in Rail',
        target: 'http://womeninrail.org/',
      },
      {
        label: 'Young Rail Professionals',
        target: 'https://www.youngrailpro.com/',
      },
      {
        label: 'STEM',
        target: 'https://www.stem.org.uk/stem-ambassadors',
      },
      {
        label: 'Staff networks',
        target:
          'https://www.networkrail.co.uk/who-we-are/diversity-and-inclusion/our-people/',
      },
    ],
  },
  {
    headingText: 'Innovation Leadership',
    headingColor: colors.innoLeadership,
    links: [
      {
        label: 'Rail Delivery Group',
        target: 'https://www.raildeliverygroup.com/our-services.html',
      },
      {
        label: 'Rail Supply Group',
        target: 'https://railsupplygroup.com/',
      },
      /*
      {
        label: 'Technical Leadership Group (TLG)',
        target:
          'https://www.rssb.co.uk/groups-and-committees/rssb-board/technical-strategy/technical-leadership-group',
      },
      */
      {
        label: 'System Interface Committee (SICs)',
        target:
          'https://www.rssb.co.uk/research-and-innovation/system-interface-committees',
      },
      {
        label: 'Innovation Network',
        target:
          'https://www.riagb.org.uk/UnlockingInnovation/Get_involved/Innovation_Community/RIAmember/InterestGroup/Interest_Groups_Innovation.aspx',
      },
      {
        label: 'RSSB',
        target:
          'https://www.rssb.co.uk/what-we-do/key-industry-topics/horizon-scanning',
      },
    ],
  },
  {
    headingText: 'Technology Strategies and Plans',
    headingColor: colors.innoTechnology,
    links: [
      {
        label: 'ORR Business Plans',
        target:
          'https://orr.gov.uk/about-orr/what-we-do/our-strategy/business-plan',
      },
      {
        label: 'Network Rail Long Term Deployment Plan',
        target:
          'https://www.networkrail.co.uk/running-the-railway/long-term-planning/',
      },
      {
        label: 'Network Rail Technical Strategy',
        target:
          'https://www.networkrail.co.uk/industry-and-commercial/research-development-and-technology/our-strategy-for-research-development-and-technology-rdt/',
      },
      {
        label: 'The Digital Railway',
        target:
          'https://www.networkrail.co.uk/running-the-railway/railway-upgrade-plan/digital-railway/',
      },
      {
        label: 'Rail Sector Deal',
        target: 'https://www.gov.uk/government/publications/rail-sector-deal',
      },
      {
        label: 'Great British Railways Transition Team (GBRTT)',
        target: 'https://gbrtt.co.uk/what-we-do/',
      },
      {
        label: 'Open access operators',
        target:
          'https://www.nationalrail.co.uk/tocs_maps/tocs/TrainOperators.aspx',
      },
    ],
  },
  {
    headingText: 'Funding Sources',
    headingColor: colors.innoFunding,
    links: [
      {
        label: 'Current Live Opportunities',
        target: 'https://iuk.ktn-uk.org/transport/rail/#Opportunities',
      },
    ],
  },
  {
    headingText: 'R&D and Innovation Programmes',
    headingColor: colors.innoRandD,
    links: [
      {
        label: 'Network Rail R&D',
        target:
          'https://www.networkrail.co.uk/industry-and-commercial/research-development-and-technology/',
      },
      {
        label: 'Network Rail Challenge Statements',
        target:
          'https://www.networkrail.co.uk/industry-and-commercial/research-development-and-technology/research-and-development-programme/challenge-statements/',
      },
      {
        label: 'UKRRIN',
        target: 'https://www.ukrrin.org.uk/',
      },
      {
        label: 'TOC/FOC innovation challenges',
        target: '/under-construction',
      },
      {
        label: 'SPARK',
        target: 'https://www.sparkrail.org/Pages/SparkWelcome.aspx',
      },
    ],
  },
  {
    headingText: 'Cross-Sector Innovation Initiatives',
    headingColor: colors.innoCross,
    links: [
      {
        label: 'InnovateUK Knowledge Transfer Network (KTN) Transport field',
        target: 'https://iuk.ktn-uk.org/',
      },
      {
        label: 'Connected Places Catapult',
        target: 'https://cp.catapult.org.uk/',
      },
    ],
  },
  {
    headingText: 'Advice and Support',
    headingColor: colors.innoAdvice,
    links: [
      {
        label: 'RIA Innovation Conference',
        target:
          'https://www.riagb.org.uk/RIA/RIA_Events/Copy_of_Register_for_an_Event/Open_to_All/RIA/Events_Open.aspx',
      },
      {
        label: 'Unlocking Innovation',
        target: 'https://www.riagb.org.uk/UnlockingInnovation',
      },
      {
        label: 'Exporting innovation',
        target: 'https://www.riagb.org.uk/RIA/Who_we_are/OurWork/Exports.aspx',
      },
      {
        label: 'Developing Your Organisation’s Innovation Strategy',
        target:
          'https://www.riagb.org.uk/UnlockingInnovation/Navigator/Unlocking_Innovation/Navigator/UINavigator.aspx',
      },
      {
        label: 'Understanding the Rail Industry Structure',
        target: '/UK_Rail_Stakeholder_Map.pdf',
      },
      {
        label: 'Connected Places Catapult',
        target: 'https://cp.catapult.org.uk/sector/rail-stations/',
      },
      {
        label: 'Scale up advice',
        target: 'https://www.innovateukedge.ukri.org/',
      },
    ],
  },
  {
    headingText: 'Industry Processes',
    headingColor: colors.innoIndustry,
    links: [
      {
        label: 'Network Rail Product Acceptance',
        target:
          'https://www.networkrail.co.uk/industry-and-commercial/research-development-and-technology/product-acceptance/',
      },
      {
        label: 'RSSB',
        target: 'https://www.rssb.co.uk/',
      },
      {
        label: 'Office for Rail and Road (ORR)',
        target: 'https://www.orr.gov.uk/rail-guidance-compliance',
      },
      {
        label: 'London Underground product acceptance',
        target:
          'https://www.spglobal.com/engineering/en/Permission/lul-standards/index.html',
      },
      {
        label: 'Innovation with TfL',
        target:
          'https://tfl.gov.uk/info-for/business-and-advertisers/open-innovation?intcmp=55927',
      },
      {
        label: 'Innovation at HS2',
        target: 'https://www.hs2.org.uk/building-hs2/innovation/',
      },
      {
        label: 'Rail Industry Supplier Qualification Scheme (RISQs)',
        target: 'https://www.risqs.org/',
      },
    ],
  },
  {
    headingText: 'Testing and Trailling',
    headingColor: colors.innoTesting,
    links: [
      {
        label: 'Testing and Trialling Facilities',
        target: '/under-construction',
      },
      /*
      {
        label: 'Academic institutions',
        target: 'http://www.google.co.uk',
      },
      */
      {
        label: 'UKRINN',
        target: 'https://www.ukrrin.org.uk/',
      },
      {
        label: 'Global Centre of Rail Excellence (GCRE)',
        target: 'https://www.gcre.wales/',
      },
      {
        label: 'Network Rail Innovation and Development Centre (RIDC)',
        target:
          'https://www.networkrail.co.uk/industry-and-commercial/research-development-and-technology/rail-innovation-development-centres/',
      },
      {
        label: 'Guidance on Testing and Trialling',
        target: '/under-construction',
      },
      /*
      {
        label: 'Testing Vouchers',
        target:
          'https://www.rssb.co.uk/innovation-programme/supporting-innovation-in-rail/inter-organisation/testing-and-trialling',
      },
      */
      {
        label: 'Rolling Stock Leasing Companies (ROSCOs)',
        target:
          'https://www.orr.gov.uk/about/who-we-work-with/industry/rolling-stock-companies',
      },
    ],
  },
  {
    headingText: 'Innovation Capability and Skills',
    headingColor: colors.innoSkills,
    links: [
      /*
      {
        label: 'Supply Chain Capability and Mapping',
        target: 'http://www.google.co.uk',
      },
      */
      {
        label: 'InnovateUK KTN - Knowledge Transfer partnerships (KTP)',
        target:
          'https://iuk.ktn-uk.org/programme/knowledge-transfer-partnerships/',
      },
      {
        label: 'Innovation, Culture and Maturity Programme',
        target: '/Culture_of_Innovation_Guide.pdf',
      },
      /*
      {
        label: 'The innovation Charter',
        target: 'http://www.google.co.uk',
      },
      */
      {
        label: 'Measuring Your Innovation Capability',
        target: 'https://navigator.riagb.org.uk/Self-Assessment',
      },
    ],
  },
  {
    headingText: 'Industry Structure',
    headingColor: colors.innoIndustry,
    links: [
      {
        label: 'Understanding the rail industry structure',
        target: 'https://www.riagb.org.uk/unlockinginnovation/KnowYourIndustry',
      },
      {
        label: 'Know Your Industry',
        target: 'https://www.riagb.org.uk/unlockinginnovation/KnowYourIndustry',
      },
      {
        label: 'Know Your Customers',
        target: 'https://www.riagb.org.uk/unlockinginnovation/KnowYourCustomer',
      },
      {
        label: 'UK, Scottish, and Welsh governments',
        target: '/under-construction',
      },
      {
        label: 'Sub National Transport Bodies',
        target: '/under-construction',
      },
      {
        label: 'RIA Nations and Regions',
        target:
          'https://www.riagb.org.uk/RIA/AboutUs/NR/RIA/Nations_and_Regions.aspx',
      },
    ],
  },
  {
    headingText: 'Skills and Development',
    headingColor: colors.innoAdvice,
    links: [
      {
        label: 'Apprenticeship Levy Scheme',
        target: 'https://www.nsar.co.uk/services/apprenticeship-services/',
      },
      {
        label: 'National Skills Academy for Rail (NSAR)',
        target: 'https://www.nsar.co.uk/',
      },
      {
        label: 'Routes into Rail (RiR)',
        target: 'https://www.nsar.co.uk/routes-into-rail/',
      },
      {
        label: 'Professional institutions',
        target: '/under-construction',
      },
    ],
  },
];

export default innovationLinks;
