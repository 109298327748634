import React, { useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import HeadingMedium from 'components/core/typography/headings/medium/HeadingMedium';
import chartProps from './Result.chart.options';
import { SelfAssessmentContext } from 'context/SelfAssessmentContext/SelfAssessmentContext';
import Paragraph from 'components/core/typography/paragraph/Paragraph';
import Heading from 'components/core/typography/headings/large/HeadingLarge';
import styles from './Results.style';
import Pill from 'components/core/buttons/Pill/Pill';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import RadarChart from 'components/core/graphs/RadarGraph/RadarGraph';
import { Link } from '@mui/material';
const NavigatorURL =
  'https://www.riagb.org.uk/UnlockingInnovation/Navigator/Unlocking_Innovation/Navigator/UI_Navigator.aspx?hkey=d485789c-23ad-4b3c-822d-2e9a89469dcd';
const Results = () => {
  const selfAssessmentContext = useContext(SelfAssessmentContext);
  const resultData = selfAssessmentContext.resultData;
  const onClick = () => (window.location.href = NavigatorURL);

  useEffect(() => {
    const sendContactEmail = async () => {
      await selfAssessmentContext.sendContactEmail();
    };

    sendContactEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box data-cy="page-results">
      <Heading sx={styles.heading} cy="self-assessment-heading">
        Your Results
      </Heading>
      <RadarChart
        cy="self-assessment-radar-chart"
        sx={styles.graph}
        options={{
          colors: chartProps.colors,
          chart: {
            id: 'self-assessment-radar-chart',
            toolbar: chartProps.toolbar,
            stroke: {
              show: true,
            },
          },
          stroke: chartProps.stroke,
          markers: {
            size: 0,
          },
          legend: {
            fontWeight: 'bold',
            horizontalAlign: 'right',
          },
          fill: chartProps.fill,
          plotOptions: chartProps.plotOptions,
          xaxis: {
            categories: resultData.categories,
            show: true,
            axisTicks: {
              show: true,
            },
            labels: {
              style: {
                colors: resultData.categories.map(() => chartProps.label.color),
                fontFamily: 'Montserrat',
              },
            },
          },
          yaxis: {
            min: resultData.min,
            max: resultData.max,
            tickAmount: resultData.max,
            show: false,
          },
        }}
        series={[
          {
            name: 'Current Understanding',
            data: resultData.currentScore,
          },
          {
            name: 'Desired Understanding',
            data: resultData.requiredScore,
          },
        ]}
      />
      <HeadingMedium sx={styles.subHeading} cy="self-assessment-result-heading">
        Here is your unique innovation ‘read-out’
      </HeadingMedium>
      <Paragraph>
        The spider-diagram sets out your personal innovation readiness-level
        based on the answers you have provided, showing your ‘where you
        currently are’ (green line) and ‘where you need to be’ (purple line)
        levels.
      </Paragraph>
      <Paragraph display="block">
        It includes five key factors, which are of importance to your innovation
        readiness:
        <ul>
          <li>Know your industry</li>
          <li>Know your customer</li>
          <li>Know your customer’s acceptance process</li>
          <li>Know your technology</li>
          <li>Know your capability</li>
        </ul>
      </Paragraph>
      <Paragraph>
        These factors do not represent an exhaustive list of all the enablers of
        innovation; they are intended to be used as prompts to check your
        understanding of what is needed and what you already have in place. By
        identifying these gaps your organisation will be better placed to fully
        understand their significance and you can then plan how to address them.
      </Paragraph>
      <Paragraph>
        You can print your results off or save them by clicking on the top right
        button next to the spider-diagram.{' '}
        <strong>
          Please note, these results are private to you and will not be kept or
          used by RIA in any capacity.
        </strong>
      </Paragraph>
      <HeadingMedium sx={styles.subHeading} cy="self-assessment-result-how-use">
        How to use your results
      </HeadingMedium>
      <Paragraph>
        Have a look at your spider diagram and at each of the five factors
        (points on the diagram). When the purple line is nearer a point of the
        diagram than the green line, it marks an area where further work is
        required. You should work to develop your knowledge of these factors as
        a priority. Where the green and purple lines meet or the green is closer
        to the point than the purple line, you have a good level of knowledge of
        this factor and you should maintain your current level of work.
      </Paragraph>
      <Paragraph>
        It should be recognised that the scores on the self-assessment tool will
        not be static, but will change as you progress your innovation. The
        scores should not be expected to reach the maximum point on the scale
        immediately, but should generally increase in time as you develop your
        innovation, and organisational capability, further.
      </Paragraph>
      <HeadingMedium sx={styles.subHeading} cy="self-assessment-result-improve">
        How to improve your score?
      </HeadingMedium>
      <Paragraph>
        {' '}
        Each company or individual will have a different innovation readiness.
        Below you can click on each of the five factors to find out how to
        improve your score and boost your innovation readiness level.
      </Paragraph>
      <Paragraph>
        <ol>
          <li>
            <Link
              href="https://www.riagb.org.uk/unlockinginnovation/KnowYourIndustry"
              rel="noopener noreferrer"
              target="_blank"
            >
              Improve ‘Know Your Industry’
            </Link>
          </li>
          <li>
            <Link
              href="https://www.riagb.org.uk/unlockinginnovation/KnowYourCustomer"
              rel="noopener noreferrer"
              target="_blank"
            >
              Improve ‘Know Your Customer’
            </Link>
          </li>
          <li>
            <Link
              href="https://www.riagb.org.uk/unlockinginnovation/KnowYourCustomersAcceptanceProcess "
              rel="noopener noreferrer"
              target="_blank"
            >
              Improve ‘Know Your Customer’s Acceptance Process’
            </Link>
          </li>
          <li>
            <Link
              href="https://www.riagb.org.uk/unlockinginnovation/KnowYourTechnology"
              rel="noopener noreferrer"
              target="_blank"
            >
              Improve ‘Know Your Technology’
            </Link>
          </li>
          <li>
            <Link
              href="https://www.riagb.org.uk/unlockinginnovation/KnowYourCapability"
              rel="noopener noreferrer"
              target="_blank"
            >
              Improve ‘Know Your Capability’
            </Link>
          </li>
        </ol>
      </Paragraph>
      <HeadingMedium sx={styles.subHeading} cy="self-assessment-result-how-use">
        Get in touch
      </HeadingMedium>
      <Paragraph>
        The Railway Industry Association and our Unlocking Innovation programme
        supports businesses throughout their innovation journey. To find out how
        we can support you, contact us at:{' '}
        <ul>
          <li>
            Email: <Link href="mailto:ria@riagb.org.uk">ria@riagb.org.uk</Link>
          </li>
          <li>Phone: +44(0)20 7201 0777</li>
          <li>
            Website:{' '}
            <Link
              href="https://www.riagb.org.uk/"
              rel="noopener noreferrer"
              target="_blank"
            >
              www.riagb.org.uk
            </Link>
          </li>
        </ul>
      </Paragraph>
      <Box sx={styles.footer}>
        <Pill
          label="Back to Unlocking Innovation"
          endIcon={<NavigateNextRoundedIcon />}
          sx={styles.pillButton}
          onClick={onClick}
        />
      </Box>
    </Box>
  );
};

Results.propTypes = {};
export default Results;
