import stylesConfig from 'theme/config';
import styleConfig from 'theme/config';

const styles = {
  container: {
    marginBottom: stylesConfig.spacing.standard,
  },
  label: {
    marginBottom: styleConfig.spacing.small,
    color: styleConfig.colors.black,
    fontWeight: '500',
    font: 'normal normal medium 18px/22px Montserrat',
  },
  error: {
    '.MuiFormControl-root': {
      border: `1px solid ${stylesConfig.colors.red}`,
    },
  },
  input: {
    paddingBottom: styleConfig.spacing.verySmall,
    paddingTop: styleConfig.spacing.verySmall,
    paddingLeft: styleConfig.spacing.small,
    paddingRight: styleConfig.spacing.small,
    marginBottom: styleConfig.spacing.verySmall,
    border: `1px solid ${stylesConfig.colors.darkerGrey}`,
    width: '100%',
  },
};

export default styles;
