import React, { useContext } from 'react';

import { LoggedOutRoutes } from './LoggedOutRoutes';
import { LoggedInRoutes } from './LoggedInRoutes';

import { GlobalContext } from '../context/GlobalContext';

export const Routes = () => {
  const globalContext = useContext(GlobalContext);

  if (globalContext.user) {
    return <LoggedInRoutes />;
  }

  return <LoggedOutRoutes />;
};
