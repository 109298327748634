import stylesConfig from 'theme/config';

const styles = {
  default: {
    borderRadius: '40px',
    textTransform: 'none',
    padding: '12px 16px',
    fontWeight: 'bold',
    background: stylesConfig.colors.primary,
    color: 'white',
    '&:hover': {
      background: stylesConfig.colors.primary,
    },
    '.MuiButton-endIcon': {
      background: stylesConfig.colors.white,
      borderRadius: '100%',
      color: stylesConfig.colors.primary,
    },
    '&.Mui-disabled': {
      opacity: '0.5',
      color: stylesConfig.colors.white,
    },
  },
  naked: {
    primary: {
      background: 'transparent',
      border: '0',
      color: stylesConfig.colors.primary,
      '&:hover': {
        background: 'transparent',
      },
    },
    secondary: {
      background: 'transparent',
      border: '0',
      color: stylesConfig.colors.secondary,
      '&:hover': {
        background: 'transparent',
      },
    },
  },

  secondary: {
    background: stylesConfig.colors.secondary,
    color: 'white',
    '.MuiButton-endIcon': {
      background: stylesConfig.colors.white,
      color: stylesConfig.colors.secondary,
    },
  },
};

export default styles;
