import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box/Box';
import styles from './Description.style';
import Paragraph from 'components/core/typography/paragraph/Paragraph';
const Description = ({ text, cy, children, sx }) => {
  return (
    <Box data-cy={cy} sx={{ ...styles.container, ...sx }}>
      <Paragraph>
        {text}
        {children}
      </Paragraph>
    </Box>
  );
};

Description.defaultProps = {
  cy: 'paragraph',
};

Description.propTypes = {
  text: PropTypes.string,
  cy: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
export default Description;
