import React, { useContext } from 'react';
import BasicPageLayout from 'components/layouts/logged-out/BasicPageLayout/BasicPageLayout';
import SelfAssessmentContextProvider from 'context/SelfAssessmentContext/SelfAssessmentContext';
import { SelfAssessmentContext } from 'context/SelfAssessmentContext/SelfAssessmentContext';

const SelfAssessment = () => {
  const selfAssessmentContext = useContext(SelfAssessmentContext);

  return (
    <BasicPageLayout>
      {selfAssessmentContext.stepsSchema.map((schema) => {
        return (
          <React.Fragment key={schema.id}>
            {schema.id === selfAssessmentContext.index && schema.component}
          </React.Fragment>
        );
      })}
    </BasicPageLayout>
  );
};

const SelfAssessmentWithContext = () => {
  return (
    <SelfAssessmentContextProvider>
      <SelfAssessment />
    </SelfAssessmentContextProvider>
  );
};
export default SelfAssessmentWithContext;
