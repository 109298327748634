import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Pill from 'components/core/buttons/Pill/Pill';
import styles from './Paging.styles';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
const Paging = ({
  showBack,
  disableBack,
  showForward,
  disableForward,
  backHandler,
  forwardHandler,
  nextLabel,
  backLabel,
  backStyles,
}) => {
  return (
    <Box sx={styles.container}>
      <Box>
        {showBack && (
          <Pill
            cy="paging-backward"
            sx={backStyles}
            naked
            theme="secondary"
            label={backLabel}
            onClick={backHandler}
            disbled={disableBack}
          />
        )}
      </Box>
      <Box>
        {showForward && (
          <Pill
            cy="paging-forward"
            disabled={disableForward}
            label={nextLabel}
            onClick={forwardHandler}
            endIcon={<NavigateNextRoundedIcon />}
          />
        )}
      </Box>
    </Box>
  );
};

Paging.defaultProps = {
  showBack: true,
  showForward: true,
  backHandler: () => {},
  forwardHandler: () => {},
  nextLabel: '',
  backLabel: '',
  disableForward: false,
  disableBack: false,
  backStyles: undefined,
};

Paging.propTypes = {
  showBack: PropTypes.bool,
  showForward: PropTypes.bool,
  backHandler: PropTypes.func,
  forwardHandler: PropTypes.func,
  nextLabel: PropTypes.string,
  backLabel: PropTypes.string,
  disableForward: PropTypes.bool,
  disableBack: PropTypes.bool,
  backStyles: PropTypes.shape({}),
};
export default Paging;
