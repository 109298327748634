import stylesConfig from 'theme/config';

const styles = {
  checkbox: {
    marginBottom: stylesConfig.spacing.veryLarge,
    '.MuiTypography-root': {
      font: 'bold normal normal 12px/18px Montserrat',
    },
  },
};

export default styles;
