import React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/core/form/Input/Input';
import Box from '@mui/material/Box';
import styles from './InputFlat.style';
import InputLabel from '@mui/material/InputLabel';
import ErrorHelperText from '../ErrorHelperText/ErrorHelperText';
const InputFlat = ({
  id,
  cy,
  label,
  variant,
  inputProps,
  required,
  sx,
  helperText,
  error,
  onChange,
  type,
  defaultValue,
  disabled,
  autoComplete,
  value,
  multiline,
  placeholder,
  onKeyPress,
  inputContainerStyles,
  autoFocus,
  ref,
}) => {
  return (
    <Box
      sx={
        error
          ? { ...styles.container, ...inputContainerStyles, ...styles.error }
          : { ...styles.container, ...inputContainerStyles }
      }
    >
      <InputLabel sx={styles.label} error={error}>
        {label}
      </InputLabel>
      <Input
        id={id}
        ref={ref}
        autoFocus={autoFocus}
        placeholder={placeholder}
        defaultValue={defaultValue}
        error={error}
        autoComplete={autoComplete}
        cy={cy}
        value={value}
        required={required}
        inputProps={inputProps}
        variant={variant}
        onChange={onChange}
        sx={{ ...styles.input, ...sx }}
        type={type}
        disabled={disabled}
        multiline={multiline}
        disableUnderline
        onKeyPress={onKeyPress}
      />
      {error && helperText && (
        <ErrorHelperText text={helperText} cy={`field-error-text-${cy}`} />
      )}
    </Box>
  );
};

InputFlat.defaultProps = {
  variant: 'standard',
  required: undefined,
  inputProps: undefined,
  sx: undefined,
  error: false,
  helperText: undefined,
  onChange: undefined,
  type: 'text',
  defaultValue: undefined,
  disabled: false,
  autoComplete: undefined,
  value: undefined,
  multiline: false,
  placeholder: '',
  onKeyPress: () => {},
  autoFocus: false,
};

InputFlat.propTypes = {
  id: PropTypes.string,
  cy: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  value: PropTypes.string,
  multiline: PropTypes.bool,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  onKeyPress: PropTypes.func,
};
export default InputFlat;
