import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import styles from './Bold.style';
const Bold = ({ text, cy, children, sx }) => {
  return (
    <Typography
      component="h5"
      variant="h5"
      data-cy={cy}
      sx={{
        ...styles.heading,
        ...sx,
      }}
    >
      {children}
      {text}
    </Typography>
  );
};

Bold.defaultProps = {
  cy: 'heading-tiny',
  sx: undefined,
};

Bold.propTypes = {
  text: PropTypes.string,
  cy: PropTypes.string,
  sx: PropTypes.shape({}),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Bold;
