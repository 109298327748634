import stylesConfig from 'theme/config';
const checkbox = {
  textAlign: 'end',
  '.MuiCheckbox-root': {
    '&.Mui-checked': {},
    padding: 0,
  },
  '.MuiSvgIcon-root': {
    fontSize: '30px',
  },
};
const styles = {
  header: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: stylesConfig.spacing.veryLarge,
  },
  columnLabel: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  questonNumber: {
    width: '90px',
    height: '90px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `4px solid ${stylesConfig.colors.primary}`,
    borderRadius: '100%',
    color: stylesConfig.colors.secondary,
    font: ' normal normal 50px/63px Montserrat',
  },
  container: {
    display: 'flex',
    justifyContent: ' flex-end',
    alignItems: 'center',
    marginBottom: stylesConfig.spacing.large,
    '.question': {
      font: stylesConfig.p.font,
    },
  },
  back: {
    paddingLeft: 0,
  },
  helperText: {
    textAlign: 'end',
  },
  checkboxRequired: {
    ...checkbox,
    '.MuiCheckbox-root': {
      color: stylesConfig.colors.primary,
    },
  },
  checkboxCurrent: {
    ...checkbox,
    '.MuiCheckbox-root': {
      color: stylesConfig.colors.success,
    },
  },
};

export default styles;
