const questions = {
  1: {
    question:
      'Which of the following statements best describes your knowledge of the rail industry?',
    showBack: false,
    answers: [
      {
        id: 1,
        value: 7,
        label:
          '<strong><u>Comprehensive understanding of the rail industry.</u></strong> Comprehensive understanding of the major bodies that operate in the rail industry, the terminology that is used to communicate in the rail industry and a strong understanding of major programmes that are underway and planned. The firm exploits its understanding of the rail industry to gain credibility with partners, suppliers and potential customers. The understanding is an enabler rather than a barrier to innovation.',
      },
      {
        id: 2,
        value: 5,
        label:
          '<strong><u>Moderate understanding of the rail industry.</u></strong> Moderate understanding of the major bodies that operate in the rail industry, the terminology that is used to communicate in the rail industry and a growing understanding of major programmes that are underway and planned. The firm has a growing understanding of network of contacts and is in regular dialogue with these contacts.',
      },
      {
        id: 3,
        value: 3,
        label:
          '<strong><u>Limited understanding of the rail industry.</u></strong> In particular limited understanding of the major bodies that operate in the rail industry, the terminology that is used to communicate in the rail industry and a limited understanding of major programmes that are underway and planned in the industry. The firm has a very limited network of contacts. The lack of understanding is a barrier rather than an enabler to innovation.',
      },
    ],
  },
  2: {
    question:
      'Which of the following statements best describes your knowledge of your customers?',
    showBack: true,
    answers: [
      {
        value: 7,
        id: 1,
        label:
          '<strong><u>A strong customer relationship has been established with your customer.</u></strong> The customer routinely seeks your support, values your judgement and regularly engages with you in an open and honest way. Your organisation values the relationship with the customer. The credibility that you have established provides you with valuable insight and you are prepared to ‘go the extra mile’ in order to meet their expectations.',
      },
      {
        id: 2,
        value: 6,
        label:
          '<strong><u>The customer relationship has been established and is developing positively.</u></strong> Your organisation is able to use the relationship to establish a deep understanding of customer need and the origins of this need. You understand the ways in which your customer prefers to engage. You have a good understanding of the formal processes and system that your customer uses to conduct their business.',
      },
      {
        id: 3,
        value: 5,
        label:
          '<strong><u>Customer engagement to date has shown that it is worth investing additional time to gain a deeper insight into customer need.</u></strong> The relevant customer representative is acting as a ‘technology or product champion’ on your behalf within their own organisation.',
      },
      {
        id: 4,
        value: 4,
        label:
          '<strong><u>Initial engagement with the appropriate individuals within the customer organisation has taken place.</u></strong> A working relationship has begun to be established and is being used to judge whether or not the technology can deliver against the customers needs and if it is worthwhile pursuing further customer engagement.',
      },
      {
        id: 5,
        value: 3,
        label:
          '<strong><u>Appropriate individuals within the customer organisation have been identified and the basis for a strong relationship has begun to be established.</u></strong> A clear statement of benefit has been developed for presentation to the customer and an initial understanding of the specific challenges and requirements of your customer have been identified.',
      },
      {
        id: 6,
        value: 2,
        label:
          '<strong><u>Potential customer organisations have been identified and work is underway to develop an initial understanding of how they operate, their challenges and their needs.</u></strong>',
      },
      {
        id: 7,
        value: 1,
        label:
          '<strong><u>Limited contact has been made with potential customer organisations to date, there is little understanding of who the potential customer organisations may be.</u></strong> Where contact has been made, it has been poorly received by the customer and you feel that you are fighting for credibility with your potential customer.',
      },
    ],
  },
  3: {
    question:
      'Which of the following statements best describes your knowledge of your customer’s acceptance process?',
    showBack: true,
    answers: [
      {
        value: 7,
        id: 1,
        label:
          '<strong><u>Your firm has direct and relevant experience of acceptance, developed from prior experiences of product or service acceptance.</u></strong> A practical understanding of how to navigate the acceptance process has been formally agreed with the customer organisation responsible for acceptance. The firm has a strong working relationship and is able to exchange views in an open manner with the individual responsible for acceptance.',
      },
      {
        id: 2,
        value: 6,
        label:
          '<strong><u>Your firm is able to exploit the experience of individuals responsible for acceptance (in the customer organisation and wider rail industry) to enhance your strategy for product or service acceptance.</strong></u>',
      },
      {
        id: 3,
        value: 5,
        label:
          '<strong><u>Your firm understands how the customer acceptance process will impact upon the development of the product or service. The firm has developed a strategy for acceptance.</u></strong>',
      },
      {
        id: 4,
        value: 4,
        label:
          '<strong><u>Individuals within the customer organisation responsible for product or service acceptance have been identified and approached.</u></strong> The firm has established a clear understanding of the process that must be followed for acceptance.',
      },
      {
        id: 5,
        value: 3,
        label:
          '<strong><u>Your firm has a general understanding of how to gain product or service acceptance and has begun to develop its understanding of the specific acceptance processes that the customer organisation operates.</u></strong>',
      },
      {
        id: 6,
        value: 2,
        label:
          '<strong><u>Your firm is developing its general understanding of how to gain product or service acceptance through web based research and through contact with relevant individuals.</u></strong>',
      },
      {
        id: 7,
        value: 1,
        label:
          '<strong><u>Currently there is limited understanding or effort being made to understand how to gain acceptance for the new product or service.</u></strong>',
      },
    ],
  },
  4: {
    question:
      'Which of the following statements best describes your knowledge of your technology?',
    showBack: true,
    answers: [
      {
        value: 7,
        id: 1,
        label:
          '<strong><u>The technology has entered initial production.</u></strong> The product or service has begun to be used on the railway and has proven in practise that it performs as intended.',
      },
      {
        id: 2,
        value: 6,
        label:
          '<strong><u>The technology is assembled in a realistic form that is representative of the scale and function of the operational product or service.</u></strong>  The technology has demonstrated that it is capable of meeting the requirement for operation in the railway environment, including actual integration of the technology with established products or services.',
      },
      {
        id: 3,
        value: 5,
        label:
          '<strong><u>The components of the technology have been demonstrated to be capable of integrated with relevant legacy products or services.</u></strong> The technology has been tested in a simulated or realistic environment in order to prove performance. The final technical design has been agreed.',
      },
      {
        id: 4,
        value: 4,
        label:
          '<strong><u>The basic components of the technology have been developed and have been put together, at a realistic scale, in order to prove that the components of the technology can work together across the correct interface.</u></strong> The performance requirements for operation in the railway environment have been established.',
      },
      {
        id: 5,
        value: 3,
        label:
          '<strong><u>Initial prototyping is underway to produce an early physical form of the technology.</u></strong> At this stage prototyping is conducted to prove the concept will work rather than to demonstrate the final form of the product or service. Initial testing is used to verify initial performance analysis.',
      },
      {
        id: 6,
        value: 2,
        label:
          "<strong><u>Initial 'proof of concept' work has been completed to provide confidence that it is technically feasible to develop the technology to full maturity within a realistic timescale and budget.</u></strong> This work may include initial performance calculations, technical drawing and internal design review.",
      },
      {
        id: 7,
        value: 1,
        label:
          '<strong><u>The initial idea for the technology, its features and its application on the railway has been established.</u></strong>',
      },
    ],
  },
  5: {
    question:
      'Which of the following statements best describes your knowledge of your capacity?',
    showBack: true,
    answers: [
      {
        value: 7,
        id: 1,
        label:
          '<strong><u>The capabilities are fully operational, accessible when needed, integrated with your existing business capabilities and are being fully exploited in order to contribute to the development activities required to produce a marketable product or service.</u></strong>',
      },
      {
        id: 2,
        value: 6,
        label:
          '<strong><u>The required capabilities have been acquired and are beginning to make a contribution to the development activities.</u></strong>',
      },
      {
        id: 3,
        value: 5,
        label:
          '<strong><u>The capabilities that are needed for the development activities have begun to be acquired.</u></strong>',
      },
      {
        id: 4,
        value: 4,
        label:
          '<strong><u>A strategy has been devised to close any gaps in the capability of your business in relation to the planned development activities.</u></strong>',
      },
      {
        id: 5,
        value: 3,
        label:
          '<strong><u>An assessment of the current capability of your business against the requirements for the development activities has been completed and gaps in the capability of your business have been identified.</u></strong>',
      },
      {
        id: 6,
        value: 2,
        label:
          '<strong><u>The people, processes and facilities that will be required to develop a marketable product or service have been defined, covering technical, financial and commercial capabilities.</u></strong>',
      },
      {
        id: 7,
        value: 1,
        label:
          '<strong><u>The people, processes and facilities that will be required to develop a marketable product or service have not been identified or defined.</u></strong>',
      },
    ],
  },
};

export default questions;
