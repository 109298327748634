import React from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
// import { PermissionsGated } from "components/context-aware/PermissionsGated/PermissionsGated";
import SidebarLink from 'components/core/links/SidebarLink/SidebarLink';
// import Permissions from "lib/permissions";

const AdminLinks = () => {
  return (
    <>
      <SidebarLink to="/" text="Home" Icon={HomeOutlinedIcon} />
    </>
  );
};

AdminLinks.propTypes = {};
export default AdminLinks;
