import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText } from '@mui/material';
const ErrorHelperText = ({ sx, text, cy }) => {
  return (
    <FormHelperText error sx={sx} data-cy={cy}>
      {text}
    </FormHelperText>
  );
};

ErrorHelperText.defaultProps = {
  sx: undefined,
  text: '',
};

ErrorHelperText.propTypes = {
  sx: PropTypes.shape({}),
  text: PropTypes.string,
  cy: PropTypes.string,
};
export default ErrorHelperText;
