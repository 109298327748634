import React from 'react';
import BasicPageLayout from 'components/layouts/logged-out/BasicPageLayout/BasicPageLayout';

const UnderConstruction = () => {
  //const selfAssessmentContext = useContext(SelfAssessmentContext);

  return (
    <BasicPageLayout>
      <div style={{ textAlign: 'center' }}>
        <h1>Under Construction</h1>
        <p>
          This page is currently under construction. Please check back later.
        </p>
      </div>
    </BasicPageLayout>
  );
};

export default UnderConstruction;
